import React from 'react'
import { Grid, Container, Typography, Stack } from '@mui/material';
import MainTitle from './mainTitle';
import CourseGrid from '../muiCommonComponent/coursesButtonGrid';
import MUICard from '../muiCommonComponent/muiCard';
import CoursesSlider from '../muiCommonComponent/coursesSlider';
function SpotLight() {
    const courses = [
        {img: '/assets/img/SOET/soet.jpg', title:'School of engineering and Technology',link:'/school-of-engineering-technology'},
        {img: '/assets/img/SOL/sol.jpg', title:'School of Law',link:'/school-of-law/home'},
        {img: '/assets/img/SAHS/sahs.jpg', title:'School of Allied Health Sciences',link:'/school-of-allied-health-sciences/home'},
        {img: '/assets/img/SPS/sps.jpg', title:'School of Pharmaceutical Sciences',link:'/school-Of-pharmaceutical-sciences/home'},
        {img: '/assets/img/SBMC/sbmc.jpg', title:'School of Business Management and Commerce',link:'/school-of-business-management-and-commerce/home'},
        {img: '/assets/img/SSH/ssh.jpg', title:'School of Science and Humanities',link:'/school-of-arts-science-humanities/home'},
        // {name:'M.Sc. MLT',url:'/school-of-allied-health-sciences/msc-mlt'}
      ]; 
      const departmentsList = [
        { img: '/assets/img/SOET/cse.jpg', title: 'Computer Science and Engineering',link:'/soet-cse/home' },
        { img: '/assets/img/SOET/me.jpg', title: 'Mechanical engineering', link:'/soet-me/home' },
        { img: '/assets/img/SOET/civil.png', title: 'Civil Engineering', link:'/soet-civil/home' },
        { img: '/assets/img/SOET/ece.jpg', title: 'Electronics and Communications Engineering', link:'/soet-ece/home' },
        // Add more logo URLs as needed
    ];
      const spotLightContent=()=>(
        <Grid container spacing={3} >
      <Grid item xs={12} sm={12} md={12} lg={12} >
        
        <MainTitle title="Schools of MVN University" />
            <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color="black">
            MVN University academic structure consists of various schools, each of which represents a particular discipline or area of specialization of their study. These schools act as academic functional centers of the university. The key functions of schools are teaching, research, innovation, Knowledge Creation and extend all help to the students during the period of their study. In addition, each school is interfaced with central structures/offices, i.e., placement and training office, sports facilities, central library, interdisciplinary laboratories, student recreation centres, etc. Presently University has six schools:
            </Typography>
            <Stack marginTop={3}>
            <CoursesSlider courseList={courses}/>
            </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="MoU of MVN University" />
            <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color="black">
            MVN University functions through collaborative partnerships with various organizations such as industry, state and central universities, international universities, institutions of higher education, academic organisations and scientific societies by way of signing MOU. The main objective of these MOU’s is to keep MVN University and its schools at the forefront of innovation, research, and education. It helps MVN University in making leaders in their domain and change masters in a rapidly evolving technology ecosystem.
            </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Life at MVN University Campus" />
            <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color="black">
            MVN University had a vibrant life that provides students with a wide range of amenities, such as, outdoor and indoor games, gym, air conditioned library, canteen and cafeteria, etc. MVN campus provide world-class facilities to faculty members who are experts in their respective fields. Students have access to all resources and state-of-the-art laboratories. In addition, MVN campus offers a wide range of extracurricular activities to suit diverse interests and talents of students i.e., creation of clubs such as Academic Club, Health Club, Media and Publication Club & sports and cultural Clubs. Through service-learning programs, volunteer opportunities, and community outreach initiatives, students have the chance to make a positive impact both locally and globally. It is done through tutoring local students, participating in environmental conservation projects, and socially sponsored projects by the university.
            </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Research and Innovation at MVN University" />
            <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color="black">
            Research and Innovations are a way of life at MVN University rather than just academic endeavors. Our academic purpose is centered around research and innovation, which propels knowledge advancement and discovery. Scholarly community at MVN University is thriving for research and innovation. Our university is outfitted with top-notch infrastructure and research resources to enable innovation in a variety of scientific disciplines. Our research ecosystem work in collaboration with industry partnerships and academic collaborations which help us in converting basic findings into useful applications and augment technological advancement that helps MVN Community and Society at large.
            </Typography>
      </Grid>
    </Grid>
    )
  return (<>
  <Container>
  <MUICard title={'Spotlights'} content={spotLightContent()}/>
  </Container>
    
  </>)
}

export default SpotLight;