import React from 'react'
import { Container, Grid} from '@mui/material';
import MainTitle from './mainTitle';
import { ReactSlider } from '../../utils/utils';
import MUICard from '../muiCommonComponent/muiCard';

function Event() {
    const events = [
        {
            date: '22 March, 2023',
            title: 'PROTECTION WOMEN AND CHILDREN',
            description: 'Establish and enforce comprehensive legal frameworks that address issues such as domestic violence, child abuse, human trafficking, child labor, and child marriage.',
            link: '/single-event',
            img: 'https://admission.mvn.edu.in/wp-content/uploads/2022/02/Workshop-School-of-Law.jpg',
        },
        {
            date: '22 March, 2023',
            title: 'Decenterlizing Trust With Blockchain Technology',
            description: 'Establish and enforce comprehensive legal frameworks that address issues such as domestic violence, child abuse, human trafficking, child labor, and child marriage.',
            link: '/single-event',
            img: 'https://admission.mvn.edu.in/wp-content/uploads/2022/02/Faculty-Development-Program-Technology-Series.jpg',
        },
        {
            date: '22 March, 2023',
            title: 'PROTECTION WOMEN AND CHILDREN',
            description: 'Establish and enforce comprehensive legal frameworks that address issues such as domestic violence, child abuse, human trafficking, child labor, and child marriage.',
            link: '/single-event',
            img: 'https://admission.mvn.edu.in/wp-content/uploads/2022/02/Faculty-Development-Program-Agriculture.jpg',
        },
        {
            date: '22 March, 2023',
            title: 'PROTECTION WOMEN AND CHILDREN',
            description: 'Establish and enforce comprehensive legal frameworks that address issues such as domestic violence, child abuse, human trafficking, child labor, and child marriage.',
            link: '/single-event',
            img: 'https://admission.mvn.edu.in/wp-content/uploads/2022/02/Faculty-Development-Program-Agriculture.jpg',
        },
        {
            date: '22 March, 2023',
            title: 'PROTECTION WOMEN AND CHILDREN',
            description: 'Establish and enforce comprehensive legal frameworks that address issues such as domestic violence, child abuse, human trafficking, child labor, and child marriage.',
            link: '/single-event',
            img: 'https://admission.mvn.edu.in/wp-content/uploads/2022/02/Faculty-Development-Program-Agriculture.jpg',
        },
        // {
        //     category: 'Finance',
        //     title: 'Corporate Finance',
        //     description: "To become a CPA, you must earn a bachelor's degree in business administration, finance, or accounting and complete 150 hours of coursework.",
        //     link: '/single-courses',
        //     img: '/assets/img/bg/couress-img-6.jpg',
        // },
    ];
    const eventContent=()=>(
        <Grid container spacing={3}>
         {/* <Grid item xs={12} sm={12} md={12} lg={12} >
                        <MainTitle title="Upcoming Events"/>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12} >         
        <ReactSlider cardList={events}/>
        </Grid>
        </Grid>
    )
return (
    <Container >
        <MUICard title={'Upcoming Events'} content={eventContent()}/>   
    </Container>
)
}

export default Event