import React, { useState } from 'react';
import { PersonalInfo, ApplyCourse, Qualification, FinishScreen } from './forms';
import { baseURL } from '../../../config';
import Loader from '../Loader/Loader';
import axios from 'axios';
const SpecialRegex=/^[\w-\]+[A-Za-z\s]*$/g
const EmailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
const steps = [{ level: 'Personal Info', id: 'personal' }, { level: 'Qualification', id: 'qualification' }, { level: 'Apply Course', id: 'account' }]
function MultiStepForm() {
    const [errorStatus,setErrorStatus]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [addressCheck,setAddressCheck]=useState(false)
    const [admissionForm, setAdmissionForm] = useState({
        student_name:"",
        father_name:"",
        father_aadhar_num:'',
        mother_name:"",
        mother_aadhar_num:'',
        gurdian_name:"",
        gurdian_annual_incom:"",
        gurdain_occupation:"",
        student_nationality:"",
        student_religion:"",
        student_dob:"",
        student_gender:"",
        student_aadhar_num:"",
        postal_address:"",
        city:"",
        state:"",
        pin_code:"",
        permanent_address:"",
        permanent_city:"",
        permanent_state:"",
        permanent_pin_code:"",
        student_phone:"",
        student_email:"",
        parent_phone:"",
        category:"",
        disablity:""

    });
    const [qualification,setQualification]=useState({
        entrance_exam:[
            {entrance_type:"",application_num:"",mark_obtained:"",score:"",rank:"",year:""},
            {entrance_type:"",application_num:"",mark_obtained:"",score:"",rank:"",year:""},
            {entrance_type:"",application_num:"",mark_obtained:"",score:"",rank:"",year:""}
        ],
        subjects:[
            {subject:"Physics",board:"",max_mark:"",max_obtained:"",percentage:""},
            {subject:"Math/Biology/Bio-tech",board:"",max_mark:"",max_obtained:"",percentage:""},
            {subject:"Chemistry/Computer Science",board:"",max_mark:"",max_obtained:"",percentage:""},
            {subject:"English",board:"",max_mark:"",max_obtained:"",percentage:""},
            {subject:"Total",board:"",max_mark:"",max_obtained:"",percentage:""}
        ],
        higher_exam:"",
        board:"",
        college_name:"",
        passing_year:"",
        roll_num:"",
        migration_num:"",
        max_mark:"",
        max_obtained:"",
        percentage:"",
        facilities:"",
        type_facilities:"",
        transport_service:"",
        pickup_station:""
    })
    const [applyCourse,setApplyCourse]=useState({
        course_name:"",
        engineering:"",
        management:"",
        law:"",
        huminities:"",
        health_science:"",
        pharmaceutical_sciences:""
    })
    const [currentStep, setCurrentStep] = useState(0);
    const handleSubmit=(e)=>{
        let payload={
         ...admissionForm,
         ...applyCourse,
         ...qualification   
        }
        e.preventDefault()
        setIsLoading(true)
        if (Boolean(admissionForm.student_name.match(SpecialRegex))==false || Boolean(admissionForm?.student_email.match(EmailRegex))==false|| admissionForm?.student_phone<10) {
            setErrorStatus(true)
            setIsLoading(false)
            // var x = document.getElementById("snackbar");
            // x.className = "show";
            // setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
        }
        else {
            let url = `${baseURL}/admissionForm`
            axios.post(url, payload, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
      
                }
            }).then((response) => {
                var x = document.getElementById("successsnackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
                setIsLoading(false)
                
            }).catch(error=>{
              setIsLoading(false)
            })
      
        }
    }
    return (<>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-11">
                    <div className="card pt-4 pb-0 mt-3 mb-3 text-center">
                        <h2 id="heading">Admission Form for Student</h2>
                        <p>Fill all form field to go to next step</p>
                        <form id="msform">

                            <ul id="progressbar" >
                                {steps?.map((item, ind) => {
                                    return (
                                        <li className={ind <= currentStep && "active"} id={item.id}><strong>{item.level}</strong></li>
                                    )
                                })}
                            </ul>
                            {currentStep == 0
                                ? <PersonalInfo 
                                currentStep={currentStep} 
                                setCurrentStep={setCurrentStep} 
                                state={admissionForm} 
                                setState={setAdmissionForm} 
                                errorStatus={errorStatus} 
                                setErrorStatus={setErrorStatus}
                                setAddressCheck={setAddressCheck}
                                addressCheck={addressCheck}
                                />
                                // : currentStep == 1
                                //     ? <PermanentAddress currentStep={currentStep} setCurrentStep={setCurrentStep} state={addressInfo} setState={setAddressInfo}/>
                                    : currentStep == 1
                                        ? <Qualification currentStep={currentStep} setCurrentStep={setCurrentStep} state={qualification} setState={setQualification}/>
                                        : currentStep == 2 &&
                                            <ApplyCourse currentStep={currentStep} setCurrentStep={setCurrentStep} state={applyCourse} setState={setApplyCourse} handleSubmit={handleSubmit}/>
                                            // : <FinishScreen />
                            }

                        </form>
                    </div>
                </div>
            </div>
        </div>
        {isLoading&&<Loader/>}
    </>)
}

export default MultiStepForm;
