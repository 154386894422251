import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function ResearchDevelopmentHome() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Research and Development Cell" /> 
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>



                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>



                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title ">                                    
                                    <h3>About us</h3>
                                </div>
                                <p>
                                At MVN University, we are dedicated to fostering a vibrant research environment that encourages intellectual curiosity, innovative thinking, and academic excellence. Our PhD and Postdoctoral Research Programs offer unparalleled opportunities for aspiring researchers to pursue their passion, expand their knowledge, and make significant contributions to their respective fields.
                                </p>
 


                            </div>
                        </div>
                    </div>


                </div>
            </section>

    </>
  )
}

export default ResearchDevelopmentHome