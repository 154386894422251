import React, { useEffect, useRef } from 'react';
import { Grid, Typography, Link } from "@mui/material";

const Notifications=()=>{

    const tickerRef = useRef(null);


    const notificationList=[
        {title:'Agriculture  Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/Agriculture  Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'B.Sc.Biotech. Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/B.Sc.Biotech. Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'B.Sc.Med.M.Bio Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/B.Sc.Med.M.Bio Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'B.Sc.MLT Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/B.Sc.MLT Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'B.Tech. CSE Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/B.Tech. CSE Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'BA(POL.)Sc. Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/BA(POL.)Sc. Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'BALL.B. Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/BALL.B. Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'BBALL.B. Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/BBALL.B. Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'BCA Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/BCA Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'DMLT Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/DMLT Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'LL.B.& LLM Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/LL.B.& LLM Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'M.Sc.M.Bio Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/M.Sc.M.Bio Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'Pharmacy Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/Pharmacy Theory Date Sheet End Term Examinations June - 2024.pdf'},
        {title:'SBMC Theory Date Sheet End Term Examinations June - 2024',url:'/assets/pdf/upcomingevent/SBMC Theory Date Sheet End Term Examinations June - 2024.pdf'},
    ]
 

    useEffect(() => {
        const ticker = tickerRef.current;
        const clone = ticker.cloneNode(true);
        ticker.parentNode.appendChild(clone);

        return () => {
        clone && clone.remove();
        };
    }, []);

    return(
        /*
        <Grid container spacing={2}>
            {notificationList.map((notiy)=>(
                <Grid item xs={12}>
            <span style={{ marginRight: '8px' }}>•</span><Link href={notiy.url} target='_blank'>{notiy.title}</Link>
                 
            </Grid>
            ))}
            
        </Grid> **/

        <div className="news-ticker-vertical">
          <div className="ticker-wrap-vertical">
            <div className="ticker-vertical" ref={tickerRef}>
              {notificationList.map((item, index) => (
                <a key={index} href={item.url} className="ticker-item-vertical" target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        </div>  
    )
}
export default Notifications;