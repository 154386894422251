import React from 'react'
import Header from '../../headerone/Main'
import { Link } from 'react-router-dom'
import First from './first'
import NewMain from '../../headerone/newMain'
import Bredcom from '../../Bredcom/Main'

function ExaminationDatesheet() {
  return (
    <>
        <NewMain />
        <Bredcom title="Home" subtitle="Examination Datesheet" />

        
        <section className="project-detail">
            <div className="container">
                <div className="lower-content">
                    <div className="row">
                        <div className="text-column col-lg-12 col-md-12 col-sm-12">

                        <h2 class="text-center"> Examination Datesheet
            </h2>
            <br/> 


                            <div className="inner-column">
                            <ul class="details_list">
                                
                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/B.Com.(H), BBA & MBA  2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">B.Com.(H), BBA & MBA  2nd Sem. Mid Term Theory Date Sheet April - 2024.</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/B.Sc.MLT 2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">B.Sc.MLT 2nd Sem. Mid Term Theory Date Sheet April - 2024. </a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/B.Tech. CSE 2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">B.Tech. CSE 2nd Sem. Mid Term Theory Date Sheet April - 2024.</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/BA(POL.)Sc. 2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">BA(POL.)Sc. 2nd Sem. Mid Term Theory Date Sheet April - 2024.</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/BALL.B. 2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">BALL.B. 2nd Sem. Mid Term Theory Date Sheet April - 2024.</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/BBALL.B. 2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">BBALL.B. 2nd Sem. Mid Term Theory Date Sheet April - 2024.</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/BCA 2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">BCA 2nd Sem. Mid Term Theory Date Sheet April - 2024.</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/DMLT 2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">DMLT 2nd Sem. Mid Term Theory Date Sheet April - 2024.</a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/LL.B. 2nd Sem. Mid Term Theory Date Sheet April - 2024..pdf`}  target="_blank" rel="noopener">LL.B. 2nd Sem. Mid Term Theory Date Sheet April - 2024.</a></li>

                               
                            </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)
}

export default ExaminationDatesheet