
const First=()=>{
return(
    <section className="about-area about-p pt-60 pb-60 p-relative fix">
   <div className="container">
          <div className="row justify-content-center ">
            <div className="col-sm-12 col-lg-5 col-md-5 ">
              <div className="wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                <img src="/assets/img/prashant.jpg" alt="img" className="vc-img" />
                <div className="vc-about">
                  <p>Mr. Prashant Kumar</p>
                  <p style={{marginTop:'-20px'}}>Director (Admissions)</p>
                </div>
              </div>
              </div>
        <div className="col-sm-12 col-md-7 col-lg-7 text-justify">
          <h4>ADMISSION PROCEDURE AND REGULATIONS</h4>
          <ul>
            <li>Admission at MVN University is irrespective of caste, creed, colour &amp; religion.</li>
            <li>Admission in any course at MVN University is strictly on the basis of minimum eligibility as mentioned in “Programs at a Glance”.</li>
            <li>All Candidates seeking admissions in various programs should procure admission cum information brochure by paying Rs. 1000/• from the counter at MVN University Campus or any other authorized outlets as mentioned on the MVN website. Alternatively, they may download Admission Brochure from MVN University website. All applicants would online application form/hard copy application form after satisfying themselves with the eligibility criteria and the instructions thereof. Applicants must submit application forms along with necessary original documents to the document verification committee at the MVN University Campus.</li>
            <li>The candidates shall appear for physical counselling on specified dates mentioned in Admission Calendar.</li>
            </ul>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 text-justify">
              <ul>

              <li>On the day of counselling, candidates shall get their certificates/testimonials checked/verified from the document verification committee(s) appointed for the purpose.</li>

            <li>A seat shall be deemed to be allotted only when the candidate has deposited the fees.</li>
            <li>Candidates desirous of seeking admission in B.Tech program of 4 years duration may note that admissions in these programs shall be made on the basis of merit in JEE (Mains) 2019 as first preference. In respect of any seats remaining vacant, the admission shall be made on the basis of merit in qualifying examination i.e. 10+2/Senior Secondary Examination or equivalent.</li>
          </ul>


          <h4>Refund Policy</h4>
          <ul>
            <li>In case of a student/candidate withdrawing before the start of the course, i.e. before the commencement of classes, the entire fee collected from the student shall be refunded after deducting a processing fee of Rs. 1000 (One thousand only).</li>
            <li>If any student leaves after joining the course & if the seat consequently falling vacant has been filled by another candidate by the last date of admission, the university will refund the tuition fee collected with proportionate deductions of monthly fee & proportionate hostel rent wherever applicable.</li>
            <li>No fee will be refunded after the last date of admissions.</li>
          </ul>
        </div>
      </div>
      
    </div>
    </section>
)
}
export default First;