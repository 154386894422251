import React from "react";
import { Grid, Typography, Link } from "@mui/material";

const UpcoingEvents=()=>{
    const eventList=[
        {title:'Code Ignite - Programming Competition for B.Tech Freshers',url:'/assets/pdf/upcomingevent/code-ignites.pdf'},
    ]
    return(
        <Grid container spacing={2}>
            {eventList.map((event)=>(
                <Grid item xs={12}>
            <span style={{ marginRight: '8px' }}>•</span><Link href={event.url} target='_blank'>{event.title}</Link>
                {/* <a href=""><Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color="black">Code Ignites</Typography></a> */}
            </Grid>
            ))}
            
        </Grid>
    )
}
export default UpcoingEvents;