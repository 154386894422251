import { useState, useEffect } from "react";

export function PersonalInfo({ state, setState, currentStep, setCurrentStep, errorStatus, setErrorStatus, setAddressCheck, addressCheck }) {
    const SpecialRegex = /^[\w-\]+[A-Za-z\s]*$/g
    const EmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name == "student_phone") {
            if (value.length <= 10) {
                setState((values) => ({ ...values, [name]: value }))
            }
        } else if (name == "mother_aadhar_num" || name == "father_aadhar_num" || name == "student_aadhar_num") {
            if (value.length <= 12) {
                setState((values) => ({ ...values, [name]: value }))
            }
        }
        else if (name == "pin_code" || name == "permanent_pin_code") {
            if (value.length <= 6) {
                setState((values) => ({ ...values, [name]: value }))
            }
        }
        else {
            setState((values) => ({ ...values, [name]: value }))
        }
    }
    const handleNext = () => {
        if (state.student_name == "" || Boolean(state.student_name.match(SpecialRegex)) == false || Boolean(state?.student_email.match(EmailRegex)) == false || state?.student_phone?.length < 10) {
            setErrorStatus(true)
        }
        else {
            setCurrentStep(currentStep + 1)
            setErrorStatus(false)
        }
    }
    useEffect(() => {
        if (addressCheck) {
            setState((values) => ({
                ...values,
                postal_address: state.permanent_address,
                city: state.permanent_city,
                state: state.permanent_state,
                pin_code: state.permanent_pin_code,
                // permanent_address:state.postal_address,
                // permanent_city:state.city,
                // permanent_state:state.state,
                // permanent_pin_code:state.pin_code
            }))
        }
    }, [addressCheck])
    return (
        <fieldset>
            <div className="form-card">
                <div className="row">
                    <div className="col-7">
                        <h2 className="fs-title">Personal Information:</h2>
                    </div>
                    <div className="col-5">
                        <h2 className="steps">Step 1 - 3</h2>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Name *</label>
                        <input type="text" name="student_name" value={state?.student_name} onChange={handleInput} placeholder="Name" required />
                        {errorStatus && <span style={{ color: "red" }}>{Boolean(state?.student_name.match(SpecialRegex)) == false ? "Special character not allowed !" : state?.student_name == "" && "Please input a valid name !"}</span>}
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">E-mail *</label>
                        <input type="email" name="student_email" value={state?.student_email} onChange={handleInput} placeholder="email" />
                        {errorStatus && Boolean(state?.student_email.match(EmailRegex)) == false && <span style={{ color: "red" }}>Please input a valid email !</span>}
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Phone No (Self) * </label>
                        <input type="number" maxLength={10} name="student_phone" value={state?.student_phone} onChange={handleInput} placeholder="Phone Number" />
                        {errorStatus && state?.student_phone?.length < 10 && <span style={{ color: "red" }}>Phone number should be 10 digit !</span>}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Father`s Name Mr. </label>
                        <input type="email" name="father_name" value={state?.father_name} onChange={handleInput} placeholder="Father`s Name" required />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Mother`s Name Mrs. </label>
                        <input type="text" name="mother_name" value={state?.mother_name} onChange={handleInput} placeholder="Mother`s Name Mrs." required />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Annual Income of Father or Gurdian</label>
                        <input type="text" name="gurdian_annual_incom" value={state?.gurdian_annual_incom} onChange={handleInput} placeholder="Annual Income" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Occupation</label>
                        <input type="text" name="gurdain_occupation" value={state?.gurdain_occupation} onChange={handleInput} placeholder="Occupation" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Nationality</label>
                        <input type="text" name="student_nationality" value={state?.student_nationality} onChange={handleInput} placeholder="Nationality" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Religion</label>
                        <input type="text" name="student_religion" value={state?.student_religion} onChange={handleInput} placeholder="Religion" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Date of Birth</label>
                        <input type="date" name="student_dob" value={state?.student_dob} onChange={handleInput} placeholder="Religion" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Phone No (Parents)</label>
                        <input type="text" name="parent_phone" value={state?.parent_phone} onChange={handleInput} placeholder="Phone Number" />
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Gender</label>
                        <select
                            onChange={handleInput}
                            value={state.gender}
                            name="gender">
                            <option value=''>Select</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Category</label>
                        <select
                            onChange={handleInput}
                            value={state.category}
                            name="category">
                            <option value=''>Select</option>
                            <option value='GEN(HR)'>GEN(HR)</option>
                            <option value='GEN(All India)'>GEN(All India)</option>
                            <option value='SC(HR)'>SC(HR)</option>
                            <option value='ST(HR)'>ST(HR)</option>
                            <option value='OBC(HR)'>OBC(HR)</option>
                            <option value='PH'>PH</option>
                            <option value='KM'>KM</option>
                            <option value='Minorties'>Minorties</option>
                            <option value='EWS(HR)'>EWS(HR)</option>
                            <option value='Other State'>Other State</option>
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Disablity if any</label>
                        <select
                            onChange={handleInput}
                            value={state.disablity}
                            name="disablity">
                            <option value=''>Select</option>
                            <option value='Eyes/Eyesight'>Eyes/Eyesight</option>
                            <option value='Hearing'>Hearing</option>
                            <option value='Physical Challenged'>Physical Challenged</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Permanent Address </label>
                        <input type="text" name="permanent_address" value={state?.permanent_address} onChange={handleInput} placeholder="Address" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">City</label>
                        <input type="text" name="permanent_city" value={state?.permanent_city} onChange={handleInput} placeholder="City" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">State</label>
                        <input type="text" name="permanent_state" value={state?.permanent_state} onChange={handleInput} placeholder="State" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Pin Code</label>
                        <input type="number" name="permanent_pin_code" value={state?.permanent_pin_code} onChange={handleInput} placeholder="Pin Code" />
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1">
                        <input type="checkbox" checked={addressCheck} name="category" onChange={() => setAddressCheck(!addressCheck)} value="Same as above address" />
                    </div>
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <label for="vehicle1">Same as above address</label>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Postal Address </label>
                        <input type="text" name="postal_address" value={state?.postal_address} onChange={handleInput} placeholder="Address" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">City</label>
                        <input type="text" name="city" value={state?.city} onChange={handleInput} placeholder="City" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">State</label>
                        <input type="text" name="state" value={state?.state} onChange={handleInput} placeholder="State" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Pin Code</label>
                        <input type="number" name="pin_code" value={state?.pin_code} onChange={handleInput} placeholder="Pin Code" />
                    </div>
                </div>
            </div>
            <input type="button" name="next" className="next action-button" value="Next" onClick={handleNext} />
        </fieldset>
    );
}



export function Qualification({ currentStep, setCurrentStep, state, setState }) {
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState((values) => ({ ...values, [name]: value }))
    }
    const handleSubjectChange = (e, ind) => {
        const name = e.target.name;
        const value = e.target.value;
        let updatedSubject = state?.subjects.map((item, index) => {
            if (ind == index) {
                item[name] = value
            }
            return item
        })
        setState((values) => ({ ...values, subjects: updatedSubject }))

    }
    const handleEntanceExam = (e, ind) => {
        const name = e.target.name;
        const value = e.target.value;
        let updatedEntrance = state?.subjects.map((item, index) => {
            if (ind == index) {
                item[name] = value
            }
            return item
        })
        setState((values) => ({ ...values, entrance_exam: updatedEntrance }))

    }
    useEffect(()=>{
        if(state.facilities=="No"){
            setState((values)=>({...values,type_facilities:'',pickup_station:''}))
        }
        if(state.transport_service=="No"){
            setState((values)=>({...values,pickup_station:''}))
        }

    },[state.facilities,state.transport_service])
    return (
        <fieldset>
            <div className="form-card">
                <div className="row">
                    <div className="col-sm-12 col-md-7 col-lg-7">
                        <h2 className="fs-title">Entrance Examination Detail</h2>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-5">
                        <h2 className="steps">Step 2 - 3</h2>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <table id="admission">
                            <tr>
                                <th class="column-1"><strong>Type Of Entrance Exam</strong></th>
                                <th class="column-1"><strong>Application No.</strong></th>
                                <th class="column-1"><strong>Mark Obtained</strong></th>
                                <th class="column-1"><strong>Scores/Percentage/Percentile</strong></th>
                                <th class="column-1"><strong>Rank (All India)</strong></th>
                                <th class="column-1"><strong>Year Passing</strong></th>

                            </tr>
                            {state?.entrance_exam.map((exam, ind) => (
                                <tr>
                                    <td><input type="text" name="type_of_entrance" value={exam.type_of_entrance} onChange={(e) => handleEntanceExam(e, ind)} /></td>
                                    <td><input type="text" name="application_number" value={exam.application_number} onChange={(e) => handleEntanceExam(e, ind)} /></td>
                                    <td><input type="number" name="mark_obtained" value={exam.mark_obtained} onChange={(e) => handleEntanceExam(e, ind)} /></td>
                                    <td><input type="number" name="score" value={exam.score} onChange={(e) => handleEntanceExam(e, ind)} /></td>
                                    <td><input type="number" name="rank" value={exam.rank} onChange={(e) => handleEntanceExam(e, ind)} /></td>
                                    <td><input type="number" name="year" value={exam.year} onChange={(e) => handleEntanceExam(e, ind)} /></td>
                                </tr>
                            ))}

                        </table>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels"><b>Detail of Marks in qualifying Examination for courses where ever applicale</b></label>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: 10 }} >
                        <table id="admission">
                            <tr>
                                <th class="column-1"><strong>Subject</strong></th>
                                <th class="column-1"><strong>Board</strong></th>
                                <th class="column-1"><strong>Max Mark</strong></th>
                                <th class="column-1"><strong>Max Obtained</strong></th>
                                <th class="column-1"><strong>%</strong></th>

                            </tr>
                            {state?.subjects.map((qualify, ind) => (
                                <tr>
                                    <td><strong>{qualify.subject}</strong></td>
                                    <td><input type="text" name="board" value={qualify.board} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="max_mark" value={qualify.max_mark} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="max_obtained" value={qualify.max_obtained} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="percentage" value={qualify.percentage} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                </tr>
                            ))}

                        </table>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels"><b>Detail of Higher Education/Last Education </b></label>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Name of Examination</label>
                        <input type="text" name="higher_exam" value={state?.higher_exam} onChange={handleInput} placeholder="Name of Examination" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Board/University</label>
                        <input type="text" name="board" value={state?.board} onChange={handleInput} placeholder="Board/University" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Name of School / College</label>
                        <input type="text" name="college_name" value={state?.college_name} onChange={handleInput} placeholder="Name of School / College" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Year of Passing</label>
                        <input type="text" name="passing_year" value={state?.passing_year} onChange={handleInput} placeholder="Year of Passing" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Roll No.</label>
                        <input type="text" name="roll_num" value={state?.roll_num} onChange={handleInput} placeholder="Roll No." />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Migration Serial No</label>
                        <input type="text" name="migration_num" value={state?.migration_num} onChange={handleInput} placeholder="Migration Serial No" />
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Max Mark</label>
                        <input type="text" name="max_mark" value={state?.max_mark} onChange={handleInput} placeholder="Max Mark" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Max Obtained</label>
                        <input type="text" name="max_obtained" value={state?.max_obtained} onChange={handleInput} placeholder="Max Obtained" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Percentage</label>
                        <input type="text" name="percentage" value={state?.percentage} onChange={handleInput} placeholder="Percentage" />
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Facilities applied for</label>
                        <select
                            onChange={handleInput}
                            value={state.facilities}
                            name="facilities">
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                    </div>
                    {state.facilities == "Yes" &&
                        <div className="col-sm-12 col-md-4 col-lg-4">
                            <label className="fieldlabels">Hostal Facilities</label>
                            <select
                                onChange={handleInput}
                                value={state.type_facilities}
                                name="type_facilities">
                                <option value=''>Select</option>
                                <option value='AC'>AC</option>
                                <option value='Non-AC'>Non-AC</option>
                            </select>
                        </div>
                    }
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Transport Service</label>
                        <select
                            onChange={handleInput}
                            value={state.transport_service}
                            name="transport_service">
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                    </div>
                    {state.transport_service == "Yes" &&
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label className="fieldlabels">Station </label>
                            <input type="text" name="pickup_station" value={state?.pickup_station} onChange={handleInput} placeholder="Pickup Station" />

                        </div>
                    }
                </div>
                <input type="button" name="next" className="next action-button" value="Next" onClick={() => setCurrentStep(currentStep + 1)} />
                <input type="button" name="previous" className="previous action-button-previous" value="Previous" onClick={() => setCurrentStep(currentStep - 1)} />
            </div>
        </fieldset>
    )
}
export function ApplyCourse({ state, setState, currentStep, setCurrentStep, handleSubmit }) {
    const [engineering, setEngineering] = useState([
        { level: 'B.Tech CSE', apply: false },
        { level: 'B.Tech (Leteral Entry)', apply: false },
        { level: 'B.Sc-CS(H)', apply: false },
        { level: 'BCA', apply: false }
    ])
    const [commerce, setCommerce] = useState([
        { level: 'MBA', apply: false },
        { level: 'BBA', apply: false },
        { level: 'BBA(H)', apply: false },
        { level: 'B.Com', apply: false }
    ])
    const [law, setLaw] = useState([
        { level: 'BA+LL.B.', apply: false },
        { level: 'LL.B.', apply: false },
        { level: 'LL.M.', apply: false },

    ])
    const [science, setScience] = useState([
        { level: 'BA Political Science.', apply: false },
        { level: 'BA Economics', apply: false },

    ])
    const [healthScience, setHealthScience] = useState([
        { level: 'BPT', apply: false },
        { level: 'B.Sc.(M.L.T.)', apply: false },
        { level: 'D.M.L.T.', apply: false },
        { level: 'B.Sc. Bio Tech', apply: false },
        { level: 'B.Sc. Bio Tech(H)', apply: false },
        { level: 'M.Sc. Microbiology', apply: false },

    ])
    const [phrmaceutical, setPhrmaceutical] = useState([
        { level: 'B.Pharm', apply: false },
        { level: 'D.Pharm', apply: false },
        { level: 'B.Pharm (Literal)', apply: false },
        { level: 'M.Pharm (Pharmaceutics)', apply: false },
    ])
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState((values) => ({ ...values, [name]: value }))
    }
    return (

        <fieldset>
            <div className="form-card">
                <div className="row">
                    <div className="col-7">
                        <h2 className="fs-title">Course Applied:</h2>
                    </div>
                    <div className="col-5">
                        <h2 className="steps">Step 3 - 3</h2>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Course Applied For: </label>
                        <input type="text" name="course_name" onChange={handleInput} placeholder="Course Applied" />
                    </div>
                    <h3> Courses Please select the course applied </h3>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">School of Engineering & Technology</label>
                        <select
                            onChange={handleInput}
                            value={state.management}
                            name="management">
                            <option value=''>Select</option>
                            {engineering.map((comm) => (
                                <option value={comm.level}>{comm.level}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">School of Management & Commerce</label>
                        <select
                            onChange={handleInput}
                            value={state.engineering}
                            name="engineering">
                            <option value=''>Select</option>
                            {commerce.map((eng) => (
                                <option value={eng.level}>{eng.level}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">School of Law</label>
                        <select
                            onChange={handleInput}
                            value={state.law}
                            name="law">
                            <option value=''>Select</option>
                            {law.map((laws) => (
                                <option value={laws.level}>{laws.level}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">School of Science & Humanities</label>
                        <select
                            onChange={handleInput}
                            value={state.huminities}
                            name="huminities">
                            <option value=''>Select</option>
                            {science.map((sce) => (
                                <option value={sce.level}>{sce.level}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">School of Allied Health Science</label>
                        <select
                            onChange={handleInput}
                            value={state.health_science}
                            name="health_science">
                            <option value=''>Select</option>
                            {healthScience.map((sce) => (
                                <option value={sce.level}>{sce.level}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">School of Pharmaceutical Sciences</label>
                        <select
                            onChange={handleInput}
                            value={state.pharmaceutical_sciences}
                            name="pharmaceutical_sciences">
                            <option value=''>Select</option>
                            {phrmaceutical.map((sce) => (
                                <option value={sce.level}>{sce.level}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div> <input type="button" name="next" className="next action-button" value="Submit" onClick={handleSubmit} />
            <input type="button" name="previous" className="previous action-button-previous" value="Previous" onClick={() => setCurrentStep(currentStep - 1)} />
        </fieldset>
    )
}

export function FinishScreen({ }) {
    return (
        <fieldset>
            <div className="form-card">
                <div className="row">
                    <div className="col-7">
                        <h2 className="fs-title">Finish:</h2>
                    </div>
                    <div className="col-5">
                        <h2 className="steps">Step 3 - 3</h2>
                    </div>
                </div> <br />
                <h2 className="purple-text text-center"><strong>SUCCESS !</strong></h2> <br />
                <div className="row justify-content-center">
                    <div className="col-3"> <img src="https://i.imgur.com/GwStPmg.png" className="fit-image" /> </div>
                </div> <br />
                <div className="row justify-content-center">
                    <div className="col-7 text-center">
                        <h5 className="purple-text text-center">You Have Successfully Filling form</h5>
                    </div>
                </div>
            </div>
        </fieldset>
    )

}

  // Create similar components for Step1 and Step3
